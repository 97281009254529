<template>
  <div>
    <!-- 导航栏 -->
    <!-- <Navigation /> -->
    <!-- 内容区域 -->
    <div class="tit">{{ item.title }}</div>
    <div class="time">{{ item.date }}</div>
    <p class="text" v-html="item.text"></p>
    <!--底部-->
    <Foot />
  </div>
</template>

<script>
// import Navigation from '@/components/Navigation.vue'
import newsInfo from '@/utils/newsInfo'
import Foot from '@/components/Foot.vue'
export default {
  name: 'Product',
  components: {
    // Navigation,
    Foot
  },
  data() {
    return {
      item: {}
    }
  },
  mounted() {
    let index = Number(this.$route.query.index)
    this.item = newsInfo[index]
    console.log(this.item)
  }
}
</script>

<style lang="scss" scoped>
.tit {
  font-size: 0.46rem;
  font-weight: 400;
  color: #333333;
  padding: 0.3rem;
}
.time {
  font-size: 0.26rem;
  font-weight: 400;
  color: #999999;
  padding: 0.1rem 0.3rem;
}
.text {
  padding: 0.3rem;
  line-height: 0.4rem;
  overflow: hidden;
}
.text ::v-deep img {
  width: 100% !important;
}
</style>
